// Here you can add other styles
/*BOTONES*/
.btn-primary {
    background-color: #F59115 !important;
    border-color: #F59115;
}

.btn-primary:hover {
    border-color: #F59115;
}

.btn-primary:focus, .btn-primary.active {
    color: #FFFFFF;
    background-color: #F59115;
    border-color: #DB8213;
    box-shadow: 0 0 0 0.2rem rgb(219, 130, 19);
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
    color: #FFFFFF;
    background-color: #F59115;
    border-color: #DB8213;
    box-shadow: 0 0 0 0.2rem rgb(219, 130, 19);
}

.btn-outline-primary {
    background-color: transparent !important;
    border-color: #F59115 !important;
    color: #F59115;
}

.btn-outline-primary:hover {
    background-color: #F59115 !important;
    border-color: #F59115;
    color: #FFFFFF;
}

.btn-outline-primary:focus {
    background-color: #F59115 !important;
    border-color: #F59115;
    color: #FFFFFF;
    box-shadow: 0 0 0 0.2rem rgb(219, 130, 19);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #FFFFFF;
    background-color: #F59115;
    border-color: #F59115;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    color: #FFFFFF;
    background-color: #F59115;
    border-color: #DB8213;
    box-shadow: 0 0 0 0.2rem rgb(219, 130, 19);
}

.btn-link {
    color: #e55353;
}

.btn-link:focus {
    box-shadow: none;
}

.btn-link:hover {
    color: #e55353;

}

/*BOTONES*/


/*INPUT*/

.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgb(46 184 92 / 25%);
    border-color: #2eb85c;
}

/*INPUT*/


.row-top {
    margin-top: 1rem;
}

.row-bottom{
    margin-bottom: 1rem;
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 4px;
    min-height: 1em;
    background-color: #000;
    opacity: .25;
    margin: 7px 14px;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: transparent;
    border: 0;
    border-radius: 0.25rem;
    max-width: 50%;
    height: auto;
}

.main-logo {
    width: 30%;
    height: 30%;
    padding-left: 3em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.c-subheader a {
    color: #F59115;
}

.logout {
    color: #F59115;
}

.logout:hover {
    color: #F59115;
}

/*PAGINATION*/
.page-link{
    color:#000000;
}

.page-link:focus {
    box-shadow: 0 0 0 0.2rem rgb(46 184 92 / 25%);
}

.page-link:hover {
    color: #000000;
}

.page-item.active .page-link {
    background-color: #F59115;
    border-color: #F59115;
    color: #FFFFFF;
}

.login{
    margin: auto;
    padding: 3%;
    text-align: center;
}

/*PAGINATION*/

.dropdown-menu a:active {
    background-color: #F59115;
}

/*PROGRESS BAR*/
.progress-lg {
    height: 20px;
}
/*PROGRESS BAR*/

.c-header-toggler-icon {
    background: transparent !important;
}

/* Switch */
.c-switch-primary .c-switch-input:checked + .c-switch-slider {
    background-color: #F59115;
}
/* Switch */

.text-size{
    font-size: 1.3em;
}
.image-container {
    position: relative;
    width: 23%;
    padding-bottom: 20%; /* Adjust this to get the aspect ratio you want. This gives a 4:3 ratio. */
}

.image-container img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover; /* This scales the image as large as possible within the bounding box, cropping it if needed. */
}

.custom-link {
    color: #F59115;
    font-size: 1rem;
}

.custom-link:hover {
    color: #F59115;
}

.feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 100%;
    font-weight: bold;
}

.login-background {
    background-color: #ad2632;
}

.login-text-color {
    color: #FFFFFF;
}

.login-hr {
    background-color: #FFFFFF;
}

.forgot-password {
    font-weight: bold;
}

.vertical-line {
    width: 1px;
    height: 61px; /* Adjust the height as needed */
    background-color: #ad2632; /* Change the color as needed */
    margin: 2px;
}

.bold-header-text {
    font-weight: bold;
}

.bold-header-text-mobile {
    font-weight: bold;
    font-size: 11px;
}

.flag {
    border-radius: 25px;
}

.btn {
    border-radius: 25px !important;
}

.text-center {
    text-align: center;
}

.horizontal-line {
    width: 100%;
    height: 1px;
    background-color: #ad2632;
}

.mobile-notif {
    left: 12% !important;
}