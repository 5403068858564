.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #48d176;
    background-color: #48d176;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #48d176;
    background-color: #48d176;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b8f5be;
    border-color: #b8f5be;
}